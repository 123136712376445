<template>
  <div class="tw-flex tw-items-start tw-justify-between">
    <h1 class="tw-text-2xl tw-font-semibold">
      Assigning Costs to Projects
    </h1>
    <div class="tw-text-right tw-space-y-3 tw-w-1/3">
      <div
        class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-gap-0 lg:tw-py-4 lg:tw-px-6 tw-py-2 tw-px-2"
      >
        <state-wrapper component="AssignIcon">
          <AssignIcon
            section-type="ProjectCostBreakdown"
            :section-slug="slug"
            assign-icon-type="SectionAssign"
          />
        </state-wrapper>
      </div>
      <MarkAsComplete
        :is-complete="completed"
        :tooltip-content="completeButtonTooltip"
        :is-disabled="completeButtonDisabled"
        @handle-complete="handleComplete()"
      />
    </div>
  </div>

  <div class="tw-prose tw-prose-md tw-max-w-full">
    <CmsContentSlot :content-slot-id="blurbContentSlotId" size="small" />
  </div>

  <FadeTransition v-if="globalMessage" class="tw-mb-2">
    <banner-component :text="globalMessage" />
  </FadeTransition>

  <div class="tw-mt-6 tw-space-y-3">
    <TabGroup manual>
      <TabList>
        <CostLinkingTab
          v-for="tab in costLinkingTabs"
          :key="tab.key"
          :tab="tab"
          class="focus:tw-outline-none"
        >
          {{ tab.label }}
        </CostLinkingTab>
      </TabList>
      <TabPanels>
        <TabPanel v-for="tab in Object.keys(costLinkingTabs)" :key="tab">
          <CostLinkingTable :cost-type="tab" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </div>

  <CostLinkingModal />
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/vue";
import MarkAsComplete from "@generic/mark_as_complete.vue";
import FadeTransition from "../transitions/fade.vue";

import CostLinkingTable from "./cost_linking_table.vue";
import CostLinkingTab from "./cost_linking_tab.vue";
import CostLinkingModal from "./cost_linking_modal.vue";
import costLinkingTabs from "./cost_linking_tabs.js";
import CmsContentSlot from "../../generic/cms_content_slot.vue";
import strapiContentIds from "../../../shared/strapi_content_ids.js";
import AssignIcon from "../dynamic_forms/assign_icon.vue";

export default {
  name: "AllocateCosts",
  components: {
    TabGroup,
    TabList,
    TabPanels,
    TabPanel,
    CostLinkingTable,
    CostLinkingTab,
    CmsContentSlot,
    AssignIcon,
    FadeTransition,
    CostLinkingModal,
    MarkAsComplete
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useStore();
    const modalRef = ref(null);

    function loadCosts() {
      const projectCostBreakdown = store.getters["project_cost_breakdown/GET_PROJECT_COST_BREAKDOWN"];

      const costSlug = projectCostBreakdown.cost?.slug;

      if (!costSlug) {
        return Promise.resolve();
      }

      const fetchCosts = Object.keys(costLinkingTabs).map(costType => {
        const payload = { costSlug, subCostType: costType };

        return store.dispatch("project_cost_breakdown/FETCH_SUBCOSTS", payload);
      });

      return Promise.all(fetchCosts);
    }

    loadCosts().then(() => {
      store.commit("project_cost_breakdown/VALIDATE_SUBCOSTS");
    });

    const blurbContentSlotId = computed(() => strapiContentIds.projectCostBreakdown.allocateCost.pageBlurb);
    const projectCostBreakdown = computed(() => store.getters["project_cost_breakdown/GET_PROJECT_COST_BREAKDOWN"]);
    const globalMessage = computed(() => store.getters["project_cost_breakdown/GET_GLOBAL_VALIDATION_MESSAGE"]);
    const completed = computed(() => projectCostBreakdown.value?.state === "completed");

    const allSubcostsValid = computed(() => store.getters["project_cost_breakdown/GET_ALL_SUBCOSTS_VALID"]);

    const completeButtonDisabled = computed(
      () => !store.getters["project_cost_breakdown/GET_ALL_SUBCOSTS_VALID"] || completed.value || globalMessage.value
    );

    const completeButtonTooltip = computed(() => {
      if (completed.value) {
        return "This section has been completed, if you would like to make amendments to this section please contact your Claim Manager";
      }
      if (!allSubcostsValid.value) {
        return "Please check all your costs have been correctly assigned to projects";
      }
      if (globalMessage.value) {
        return globalMessage.value;
      }
      return null;
    });

    function handleComplete() {
      if (completeButtonDisabled.value) return;

      store.dispatch("project_cost_breakdown/COMPLETE");
    }

    return {
      costLinkingTabs,
      blurbContentSlotId,
      modalRef,
      projectCostBreakdown,
      handleComplete,
      completeButtonDisabled,
      completeButtonTooltip,
      globalMessage,
      completed
    };
  }
};
</script>
