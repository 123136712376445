<template>
  <SlideFadeTransition>
    <banner-component
      v-if="integrationRefreshData.show_info"
      type="cta"
      class="tw-mt-4"
      title="Your Xero connection refresh is now complete!"
      :text="integrationRefreshData.data_found.nominals > 0 ? `${integrationRefreshData.data_found.nominals} additional nominals found` : 'No additional nominals found'"
      :dismiss="true"
    />
  </SlideFadeTransition>
  <div>
    <div class="tw-my-6">
      <h3 :class="theme.typography.h3">
        Nominals which may qualify
      </h3>
      <p>From analysing your account codes, these accounts/nominals will likely include qualifying costs for your claim.</p>
      <PulsingLoader v-if="loadingNominals" />
      <div v-else :class="[theme.cards.large]">
        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-w-full tw-gap-2">
          <template v-for="nominal in goodNominals" :key="nominal.code">
            <div class="tw-flex tw-items-center tw-gap-x-2">
              <input
                :id="`nominal_${nominal.code}`"
                v-model="nominal.include"
                type="checkbox"
                :class="[theme.forms.checkbox]"
                :name="`nominal_${nominal.code}`"
                @change="toggleInclusion(nominal.identifier, nominal.include)"
              >
              <label class="tw-font-medium tw-p-0 tw-m-0" :for="`nominal_${nominal.code}`">{{ nominal.name }} <span class="tw-text-gray-300 tw-font-normal tw-text-sm">Code {{ nominal.code }}</span></label>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="tw-my-6">
      <h3 :class="theme.typography.h3">
        Nominals which are unlikley to qualify
      </h3>
      <p>It is likely that these accounts include costs that will not qualify for your R&D claim.</p>
      <PulsingLoader v-if="loadingNominals" />
      <div v-else :class="[theme.cards.large]">
        <FadeTransition>
          <div v-if="badNominalsIncluded.length > 0" class="tw-mb-4">
            <Banner type="warning">
              Please note that any included costs that are later deemed as non-qualifying will need to be removed before submission to HMRC.
            </Banner>
          </div>
        </FadeTransition>

        <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-w-full tw-gap-2">
          <template v-for="nominal in badNominals" :key="nominal.code">
            <div class="tw-flex tw-items-center tw-gap-x-2">
              <input
                :id="`nominal_${nominal.code}`"
                v-model="nominal.include"
                type="checkbox"
                :class="[theme.forms.checkbox]"
                :name="`nominal_${nominal.code}`"
                @change="toggleInclusion(nominal.identifier, nominal.include)"
              >
              <label class="tw-font-medium tw-p-0 tw-m-0" :for="`nominal_${nominal.code}`">{{ nominal.name }} <span class="tw-text-gray-300 tw-font-normal tw-text-sm">Code {{ nominal.code }}</span></label>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, watch } from "vue";
import { css } from "../../../../shared/theme.js";
import PulsingLoader from "../../../generic/loading_pulse_lines";
import FadeTransition from "../../../components/transitions/fade.vue";
import SlideFadeTransition from "../../../components/transitions/slide_fade.vue";
import Banner from "../../../generic/banner_component.vue";

export default {
  components: { PulsingLoader, FadeTransition, Banner, SlideFadeTransition },
  props: {
    sectionSlug: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const theme = css;
    const store = useStore();

    const goodNominals = computed(() => store.getters["accountingIntegration/GET_GOOD_NOMINALS"]);
    const badNominals = computed(() => store.getters["accountingIntegration/GET_BAD_NOMINALS"]);
    const badNominalsIncluded = computed(() => store.getters["accountingIntegration/GET_BAD_NOMINALS_INCLUDED"]);
    const loadingNominals = computed(() => store.getters["accountingIntegration/GET_LOADING_NOMINALS"]);
    const integrationRefreshData = computed(() => store.getters["accountingIntegration/GET_INTEGRATION_REFRESH_DATA"]);

    onMounted(() => {
      store.dispatch("accountingIntegration/FETCH_NOMINALS", { integrationSlug: props.sectionSlug });
      store.dispatch("accountingIntegration/FETCH_INTEGRATION_REFRESH_DATA", { integrationSlug: props.sectionSlug });
    });

    function toggleInclusion(identifier, include) {
      store.dispatch("accountingIntegration/TOGGLE_NOMINAL", { integrationSlug: props.sectionSlug, identifier, include });
    }

    watch(integrationRefreshData, val => {
      let fetchedAdditionalData = false;

      if (val?.requested && !val?.completed) {
        setTimeout(() => {
          store.dispatch("accountingIntegration/FETCH_INTEGRATION_REFRESH_DATA", { integrationSlug: props.sectionSlug });
        }, 60000);
      }

      if (val?.requested && val?.completed && !fetchedAdditionalData) {
        store.dispatch("accountingIntegration/FETCH_NOMINALS", { integrationSlug: props.sectionSlug });
        fetchedAdditionalData = true;
      }
    });

    return {
      loadingNominals,
      goodNominals,
      badNominals,
      theme,
      toggleInclusion,
      badNominalsIncluded,
      integrationRefreshData
    };
  }
};
</script>
