<template>
  <DisclosureComponent>
    <template #title-content>
      <div class="tw-flex tw-p-2 tw-items-center tw-justify-between">
        <div class="tw-flex tw-gap-4 tw-items-center">
          <div class="tw-font-semibold tw-text-lg">
            {{ label }}
          </div>
          <BadgeComponent
            v-if="!started"
            colour="disabled"
            icon="info-circle"
            type="light"
            text="Not Started"
            icon-type="fas"
          />
          <BadgeComponent
            v-if="completed"
            colour="confirm"
            icon="check-circle"
            type="light"
            text="Completed"
            icon-type="fas"
          />
          <ButtonComponent
            v-if="!completed"
            text="Send for Another Review"
            :disable-click="createReviewButtonDisabled()"
            :tooltip="disabledCreateReviewTooltip()"
            :colour="createReviewButtonDisabled() ? 'disabled' : 'cta'"
            :stop-propagation="true"
            confirm-message="Double check you have resolved any feedback left and it's unlikely for there to be any more changes to check dependencies before sending for another review"
            link="/submission/reviews"
            method="post"
            :data="{ review_process_id: slug}"
          />
        </div>
        <div class="tw-flex tw-items-center tw-gap-3">
          <Tooltip :tooltip-options="{ maxWidth: 750 }">
            <i class="fas fa-sitemap tw-text-disabled-grey tw-text-base" />
            <template #content>
              <div class="tw-flex tw-flex-col tw-p-2 tw-gap-y-3 tw-w-full tw-text-sm tw-text-left">
                <div class="tw-font-semibold">
                  Each check will have dependent properties (data points related to the claim) that determine the
                  validity of the check. If these properties change after a review has happened, you'll need to
                  send it back to the reviewers to re-check based on the new properties. They will only need to re-check
                  ones where the properties have changed.
                </div>
                <div class="tw-flex tw-flex-col tw-gap-2 tw-font-light">
                  <div>
                    This is an example of what the dependent properties may look like to help you interpret the dependencies:
                  </div>
                  <div class="tw-flex tw-items-center tw-flex-1 tw-gap-2">
                    <i class="fas fa-check-circle tw-text-confirm-green tw-pt-0.5" />
                    <div>Property unchanged since last check was made - <span class="tw-italic">Property Value</span></div>
                  </div>
                  <div class="tw-flex tw-items-center tw-flex-1 tw-gap-2">
                    <i class="far fa-circle tw-text-disable-grey tw-pt-0.5" />
                    <div>Provisional property for ongoing check - <span class="tw-italic">Property Value</span></div>
                  </div>
                  <div class="tw-flex tw-flex-col tw-gap-y-1 tw-py-0.5">
                    <div class="tw-flex tw-items-center tw-flex-1 tw-gap-2">
                      <i class="fas fa-times-circle tw-text-error-red tw-pt-0.5" />
                      <div>
                        Property that has changed in value since last check, requiring another review -
                        <span class="tw-italic">Property Value Now</span>
                      </div>
                    </div>
                    <div class="tw-pl-5 tw-flex tw-items-center">
                      <span class="tw-text-red-900 tw-italic">- Value at time of check</span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Tooltip>
          <BadgeComponent
            v-for="(count, taskStatus) in task_count_by_status"
            :key="taskStatus"
            :colour="statusMappings[taskStatus].colour"
            :icon="statusMappings[taskStatus].icon"
            type="light"
            :text="count.toString()"
            icon-type="fas"
            :tooltip="statusIconTooltip(taskStatus, count)"
          />
        </div>
      </div>
    </template>
    <template #dropdown-content>
      <div class="tw-flex tw-flex-col tw-p-2 tw-gap-y-1 tw-w-full">
        <ReviewTask
          v-for="(key, index) in task_type_keys_required"
          :key="index"
          v-bind="mostRecentTasks[key]"
          :submission="submission"
          :user-role="userRole"
        />
      </div>
    </template>
  </DisclosureComponent>
</template>

<script setup>
import { computed } from "vue";
import { pluralize } from "../../../shared/helpers.js";
import DisclosureComponent from "../../generic/disclosure.vue";
import BadgeComponent from "../../generic/badge_component.vue";
import ReviewTask from "./review_task.vue";
import Tooltip from "../../generic/tooltip.vue";
import ButtonComponent from "../../generic/button_component.vue";

const props = defineProps({
  submission: { type: Object },
  userRole: { type: String },
  label: { type: String },
  review_tasks: { type: Array },
  task_count_by_status: { type: Object },
  task_type_keys_required: { type: Array },
  completed: { type: Boolean },
  started: { type: Boolean },
  slug: { type: String },
  can_start: { type: Object },
  has_incomplete_reviews: { type: Object }
});

const mostRecentTasks = computed(() => props.review_tasks.reduce((acc, task) => {
  if (task.most_recent) {
    acc[task.key] = task;
  }
  return acc;
}, {}));

const statusMappings = {
  incomplete: { colour: "disabled", icon: "question-circle" },
  with_feedback: { colour: "warning", icon: "exclamation-circle" },
  invalid: { colour: "error", icon: "times-circle" },
  approved: { colour: "confirm", icon: "check-circle" }
};
function statusIconTooltip(status, count) {
  const countString = pluralize(count, "check", true);
  if (status === "incomplete") {
    return `${countString} not yet done`;
  } else if (status === "with_feedback") {
    return `${countString} with feedback that needs addressing`;
  } else if (status === "invalid") {
    return `${countString} with dependencies that have changed and will need another round of checks`;
  } else if (status === "approved") {
    return `${countString} done and still valid`;
  }
}

const validSubmissionStatus = computed(() => ["Submission Reviews In Progress", "Assigned, not started"].includes(props.submission?.status));

function createReviewButtonDisabled() {
  return props.has_incomplete_reviews.value || !props.can_start.value || !validSubmissionStatus.value;
}
function disabledCreateReviewTooltip() {
  if (props.has_incomplete_reviews.value) {
    return props.has_incomplete_reviews.message;
  } else if (!props.can_start.value) {
    return props.can_start.message;
  } else if (!validSubmissionStatus.value) {
    return `Submission status is ${props.submission?.status}. Please update to Submission Reviews In Progress to trigger another review.`;
  } else {
    return null;
  }
}

</script>
