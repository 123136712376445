<template>
  <Modal ref="modalRef" :show-close="false" within-sidebar>
    <template #title>
      Assigning Costs to Projects
    </template>

    <div v-if="canStart" class="tw-flex tw-flex-col tw-gap-4 tw-text-sm tw-text-gray-700">
      <p>
        Your claim for {{ projectCostBreakdown.claim_period.title_short }} with {{ projectCostBreakdown.company.name }}
        currently has <strong>{{ pluralize(rdProjectsCount, 'project', true) }}</strong> associated with it.
      </p>
      <p>Please confirm below if this is correct. Once you proceed, you won't be able to go back and add additional projects, so please make sure everything is in order.</p>
      <p>If you have any additional projects related to this claim you've not yet added, no worries! Simply reach out to your claim manager, and they'll guide you on how to return to the project selection and add them.</p>
    </div>

    <div v-else class="tw-flex tw-flex-col tw-gap-4">
      <p class="tw-text-sm tw-text-gray-700">
        To unlock this step, please ensure that the following criteria are met for
        {{ projectCostBreakdown.company.name }}'s {{ projectCostBreakdown.claim_period.title_short }} claim:
      </p>

      <ul class="tw-ml-4 tw-space-y-2">
        <li class="tw-text-sm tw-list-disc tw-list-inside tw-text-gray-700">
          The financial year for your claim period has ended
        </li>
        <li class="tw-text-sm tw-list-disc tw-list-inside tw-text-gray-700">
          You have submitted your costs for review
        </li>
        <li v-if="requiresProjectSelection" class="tw-text-sm tw-list-disc tw-list-inside tw-text-gray-700">
          Project Selection has been completed
        </li>
      </ul>

      <p class="tw-text-sm tw-text-gray-700">
        If you are unsure whether these requirements have been fulfilled, we encourage you to reach out to your claim manager for assistance.
      </p>
      <p class="tw-text-sm tw-text-gray-700">
        Alternatively, you can click
        <span class="tw-font-semibold tw-cursor-pointer tw-text-gray-900 hover:tw-text-gray-700" @click="handleClick()">
          here
        </span> to get in touch with us directly for further support.
      </p>
    </div>

    <div class="tw-pt-7 tw-flex tw-justify-end tw-gap-x-2 tw-items-center">
      <Button
        text="Back to Dashboard"
        :link="`/claim_periods/${projectCostBreakdown.claim_period.slug}/dashboard`"
        :type="canStart ? 'transparent' : 'primary'"
      />
      <Button
        v-if="canStart"
        :on-click="confirmProjectCount"
        :text="`I Confirm the Claim had ${pluralize(rdProjectsCount, 'project', true)}`"
      />
    </div>
  </Modal>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Modal from "@generic/modal_component.vue";
import Button from "@generic/button_component.vue";
import { pluralize } from "@shared/helpers.js";

const store = useStore();
const modalRef = ref(null);

const projectCostBreakdown = computed(() => store.getters["project_cost_breakdown/GET_PROJECT_COST_BREAKDOWN"]);
const requiresProjectSelection = computed(() => store.getters["project_cost_breakdown/GET_REQUIRES_PROJECT_SELECTION"]);
const rdProjectsCount = computed(() => store.getters["project_cost_breakdown/GET_RD_PROJECTS_COUNT"]);

const canStart = computed(() => projectCostBreakdown.value?.can_start);
const notStarted = computed(() => projectCostBreakdown.value?.state === "not_started");

onMounted(() => {
  if (notStarted.value) {
    modalRef.value?.show();
  }
});

function confirmProjectCount() {
  if (
    confirm("Are you sure? If you proceed you will no longer be able to add/remove projects for this claim period.")
  ) {
    store.dispatch("project_cost_breakdown/CONFIRM_PROJECT_COUNT").then(redirectUrl => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        modalRef.value?.close();
      }
    });
  }
}

function handleClick() {
  window.HubSpotConversations?.widget?.open();
}
</script>
