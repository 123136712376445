/* eslint vue/one-component-per-file:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import { createApp } from "vue/dist/vue.esm-bundler.js";
import VueClickAway from "vue3-click-away";
import PrimeVue from "primevue/config";
import * as Sentry from "@sentry/vue";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
import "tippy.js/themes/light.css";
import { quillEditor, Quill } from "vue3-quill";
import { Chart, registerables } from "chart.js";

import { createStore } from "vuex";

import ProjectGrantAllocationPage from "@components/project_grant_allocation/project_grant_allocation_page.vue";
import AccountingPeriodTable from "@components/companies/accounting_period_table.vue";
import CompaniesTable from "@components/companies/companies_table.vue";
import UsersTable from "@components/users/users_table.vue";
import AssignIcon from "@components/dynamic_forms/assign_icon.vue";
import CompanyActionCard from "@components/dashboards/company/company_action_card.vue";
import SubmissionsPage from "@components/submissions/submissions_page.vue";
import ExternalWorkersPage from "@components/external_workers/external_workers_page.vue";
import CardComponent from "../vue-components/generic/card_component.vue";
import QuestionAuditChangesButton from "../vue-components/rails-components/question_audit_changes_button.vue";

import Dropdown from "../vue-components/generic/dropdown.vue";
import ButtonComponent from "../vue-components/generic/button_component.vue";
import BadgeComponent from "../vue-components/generic/badge_component.vue";
import Tooltip from "../vue-components/generic/tooltip.vue";
import PopUpTooltip from "../vue-components/generic/pop_up_tooltip.vue";
import BannerComponent from "../vue-components/generic/banner_component.vue";
import ModalTrigger from "../vue-components/generic/modal_trigger.vue";
import ModalComponent from "../vue-components/generic/modal_component.vue";
import StateWrapper from "../vue-components/generic/state_wrapper.vue";
import FileUpload from "../vue-components/generic/file_upload.vue";
import KnowledgeCentreHelpfulWidget from "../vue-components/generic/knowledge_centre_helpful_widget.vue";
import Modal from "../vue-components/generic/modal.vue";
import FeedbackModal from "../vue-components/components/dynamic_forms/feedback_modal.vue";
import ElearnPage from "../vue-components/components/elearn/elearn_page.vue";
import CmsContentSlot from "../vue-components/generic/cms_content_slot.vue";
import PulsingLoader from "../vue-components/generic/loading_pulse_lines";
import RichTextEditor from "../vue-components/generic/rich_text_editor.vue";
import SimpleButton from "../vue-components/generic/simple_button.vue";
import Tabs from "../vue-components/generic/tabs.vue";
import ClaimProgression from "../vue-components/components/claim_progression/claim_progression.vue";
import Accordion from "../vue-components/generic/accordion.vue";
import Disclosure from "../vue-components/generic/disclosure.vue";
import SavingSpinIndicator from "../vue-components/generic/saving_spin_indicator.vue";
import KnowledgeCentreTriggerIcon from "../vue-components/generic/knowledge_centre_trigger_icon.vue";
import SectionFeedbackIcon from "../vue-components/generic/section_feedback_icon.vue";
import NotificationComponent from "../vue-components/generic/notification_component.vue";

import TabbedContent from "../vue-components/generic/tabbed_content.vue";
import CompanyClaimQuestionComponent from "../vue-components/components/dashboards/company/company_claim_question_component.vue";
import AdminSearch from "../vue-components/components/dashboards/admin/search.vue";
import ClaimsTable from "../vue-components/components/dashboards/admin/claims_table.vue";
import ClaimBenefitTable from "../vue-components/components/dashboards/admin/benefits_table.vue";
import Dashboard from "../vue-components/components/dashboards/admin/dashboard.vue";
import ClaimBenefit from "../vue-components/components/dashboards/admin/benefits.vue";
import ClaimBenefitFindOtherClaim from "../vue-components/components/dashboards/admin/benefits_find_other_claim.vue";
import TeamDropdown from "../vue-components/components/dashboards/team/team_dropdown.vue";
import ClaimSidebar from "../vue-components/views/layouts/claim_sidebar.vue";
import QualityChecks from "../vue-components/components/dashboards/admin/quality_checks.vue";
import QualityChecksAccordion from "../vue-components/components/dashboards/admin/quality_checks_accordion.vue";
import SubmissionReviewsSummary from "../vue-components/components/submissions/submission_reviews_summary.vue";
import ReviewTask from "../vue-components/components/submissions/review_task.vue";

import ReviewTable from "../vue-components/components/dashboards/reviews/review_list.vue";

import KnowledgeCentre from "../vue-components/views/layouts/knowledge_centre.vue";
import LeaveFeedbackPanel from "../vue-components/views/layouts/leave_feedback_panel.vue";
import SectionFeedback from "../vue-components/views/layouts/section_feedback.vue";
import ManageTeam from "../vue-components/views/shared/forms/manage_team.vue";
import NarrativeFeedback3 from "../vue-components/components/dynamic_forms/narrative_feedback_3.vue";
import FieldShell from "../vue-components/components/dynamic_forms/field_shell.vue";
import Paye from "../vue-components/views/finance_documents/paye.vue";
import QuestionAudit from "../vue-components/views/shared/question_audit.vue";
import QuestionAuditTrigger from "../vue-components/views/shared/question_audit_trigger.vue";
import XeroManage from "../vue-components/views/integrations/xero/manage.vue";
import AccountingManage from "../vue-components/views/integrations/accounting/manage.vue";
import AccountingNominals from "../vue-components/views/integrations/accounting/nominals.vue";
import Field from "../vue-components/components/dynamic_forms/field.vue";
import SectionStatusBadge from "../vue-components/components/sections/section_status_badge.vue";

import EPWContractsReview from "../vue-components/views/epw_contracts/epw_contracts_review.vue";
import CompanyDashboard from "../vue-components/components/dashboards/company/company_dashboard.vue";

import DynamicForm from "../vue-components/components/dynamic_forms/form.vue";
import ProjectSelection from "../vue-components/components/project_selection/project_selection.vue";
import ProjectCostBreakdown from "../vue-components/components/project_cost_breakdown/project_cost_breakdown.vue";

import ClaimChart from "../vue-components/components/dashboards/analytics/claim_chart.vue";
import RD7Chart from "../vue-components/components/dashboards/benchmarking/rd7_chart.vue";
import Cost from "../vue-components/components/costs/cost.vue";
import PrototypeCalculation from "../vue-components/components/prototype_calculation.vue";
import SetCostTaxonomy from "../vue-components/components/costs/set_cost_taxonomy.vue";

import { sections } from "../vuex-store/modules/sections.js";
import { fields } from "../vuex-store/modules/fields.js";
import { assignments } from "../vuex-store/modules/assignments.js";
import { users } from "../vuex-store/modules/users.js";
import { validations } from "../vuex-store/modules/validations.js";
import { costs } from "../vuex-store/modules/costs.js";
import { feedbacks } from "../vuex-store/modules/feedbacks.js";
import { reviews } from "../vuex-store/modules/reviews.js";
import { audit_history } from "../vuex-store/modules/audit_history";
import { states } from "../vuex-store/modules/states";
import { accountingIntegration } from "../vuex-store/modules/accounting_integration";
import { epw_contract_review } from "../vuex-store/modules/epw_contract_review";
import { company_dashboard } from "../vuex-store/modules/company_dashboard";
import { project_selection } from "../vuex-store/modules/project_selection";
import { feature_flags } from "../vuex-store/modules/feature_flags";
import { project_cost_breakdown } from "../vuex-store/modules/project_cost_breakdown";
import { project_grant_allocation } from "../vuex-store/modules/project_grant_allocation";
import { elearn } from "../vuex-store/modules/elearn";
import { external_workers } from "../vuex-store/modules/external_workers";

import ActionCableVue from "../../../vendor/assets/javascripts/actioncable-vue.min.js";
import { documentCsrfToken } from "../shared/http.js";
import { track, trackButtonClick } from "../shared/fields.js";

require("audit-log-display");
require("@nathanvda/cocoon");

Chart.register(...registerables);

const store = createStore({
  modules: {
    sections,
    fields,
    users,
    assignments,
    validations,
    costs,
    feedbacks,
    reviews,
    audit_history,
    states,
    accountingIntegration,
    epw_contract_review,
    company_dashboard,
    project_selection,
    feature_flags,
    project_cost_breakdown,
    project_grant_allocation,
    elearn,
    external_workers
  }
});

const actionCableVueOptions = {
  debug: process.env.DEBUG,
  debugLevel: process.env.WEBSOCKET_DEBUG_LEVEL,
  connectionUrl: `${process.env.WEBSOCKET_HOST}/cable?token=${documentCsrfToken()}`,
  connectImmediately: true,
  store
};

document.addEventListener("DOMContentLoaded", () => {
  const claimPeriodSlug = document.body.getAttribute("data-claim-period-slug");
  const headerApp = createApp({
    store,
    beforeCreate() {
      store.dispatch("feature_flags/FETCH_FEATURE_FLAGS", claimPeriodSlug);
    }
  });
  headerApp.config.globalProperties.track = track;
  headerApp
    .provide("track", track)
    .provide("trackButtonClick", trackButtonClick)
    .use(ActionCableVue, actionCableVueOptions)
    .use(VueClickAway)
    .use(store)
    .component("dropdown", Dropdown)
    .component("button-component", ButtonComponent)
    .component("badge-component", BadgeComponent)
    .component("state-wrapper", StateWrapper)
    .component("tooltip", Tooltip)
    .component("pop-up-tooltip", PopUpTooltip)
    .component("banner-component", BannerComponent)
    .component("KnowledgeCentreHelpfulWidget", KnowledgeCentreHelpfulWidget)
    .component("pulsingloader", PulsingLoader)
    .component("saving-spin-indicator", SavingSpinIndicator)
    .component("knowledge-centre", KnowledgeCentre)
    .component("leave-feedback-panel", LeaveFeedbackPanel)
    .component("section-feedback", SectionFeedback)
    .component("modal", Modal)
    .component("feedback-modal", FeedbackModal)
    .component("question-audit", QuestionAudit)
    .component("question-audit-button", QuestionAuditChangesButton)
    .component("question-audit-trigger", QuestionAuditTrigger)
    .mount("#vue-header");

  const app = createApp({
    store,
    beforeCreate() {
      store.dispatch("feature_flags/FETCH_FEATURE_FLAGS", claimPeriodSlug);
      $("[data-toggle='tooltip']").tooltip();
      $(".view-log").click(function () {
        const c = $(this).data("model");
        return $(`#${c}`).modal("show");
      });

      if ($("#submit_by_emp").data("value") == false) {
        $("#finance_document_submit_by_emp_false").parent().click();
      } else {
        $("#finance_document_submit_by_emp_true").parent().click();
      }
    }
  });

  app.config.globalProperties.track = track;
  app
    .provide("track", track)
    .provide("trackButtonClick", trackButtonClick)
    .use(quillEditor)
    .use(VueClickAway)
    .use(ActionCableVue, actionCableVueOptions)
    .use(store)
    .use(PrimeVue)
    .component("assign-icon", AssignIcon)
    .component("tabbed-content", TabbedContent)
    .component("card-component", CardComponent)
    .component("dropdown", Dropdown)
    .component("button-component", ButtonComponent)
    .component("badge-component", BadgeComponent)
    .component("state-wrapper", StateWrapper)
    .component("tooltip", Tooltip)
    .component("pop-up-tooltip", PopUpTooltip)
    .component("modal-trigger", ModalTrigger)
    .component("modal-component", ModalComponent)
    .component("banner-component", BannerComponent)
    .component("company-claim-question-component", CompanyClaimQuestionComponent)
    .component("admin-search", AdminSearch)
    .component("dashboard", Dashboard)
    .component("claim-benefit", ClaimBenefit)
    .component("knowledge-centre", KnowledgeCentre)
    .component("company-dashboard", CompanyDashboard)
    .component("claims-table", ClaimsTable)
    .component("claim-benefit-table", ClaimBenefitTable)
    .component("claim-benefit-find-other-claim", ClaimBenefitFindOtherClaim)
    .component("team-dropdown", TeamDropdown)
    .component("claim-sidebar", ClaimSidebar)
    .component("claim-progression", ClaimProgression)
    .component("accordion", Accordion)
    .component("tabs", Tabs)
    .component("KnowledgeCentreHelpfulWidget", KnowledgeCentreHelpfulWidget)
    .component("pulsingloader", PulsingLoader)
    .component("saving-spin-indicator", SavingSpinIndicator)
    .component("simple-button", SimpleButton)
    .component("manage-team", ManageTeam)
    .component("rich-text-editor", RichTextEditor)
    .component("xero-manage", XeroManage)
    .component("accounting-nominals", AccountingNominals)
    .component("accounting-manage", AccountingManage)
    .component("paye", Paye)
    .component("dynamic-form", DynamicForm)
    .component("project-selection", ProjectSelection)
    .component("project-cost-breakdown", ProjectCostBreakdown)
    .component("cms-content-slot", CmsContentSlot)
    .component("claim-chart", ClaimChart)
    .component("rd7-chart", RD7Chart)
    .component("narrative-feedback-3", NarrativeFeedback3)
    .component("field-shell", FieldShell)
    .component("file-upload", FileUpload)
    .component("cost", Cost)
    .component("set-cost-taxonomy", SetCostTaxonomy)
    .component("review-table", ReviewTable)
    .component("quality-checks", QualityChecks)
    .component("quality-checks-accordion", QualityChecksAccordion)
    .component("knowledge-centre-trigger-icon", KnowledgeCentreTriggerIcon)
    .component("section-feedback-icon", SectionFeedbackIcon)
    .component("prototype-calculation", PrototypeCalculation)
    .component("epw-contracts-review", EPWContractsReview)
    .component("field", Field)
    .component("elearn-page", ElearnPage)
    .component("project-grant-allocation-page", ProjectGrantAllocationPage)
    .component("section-status-badge", SectionStatusBadge)
    .component("accounting-period-table", AccountingPeriodTable)
    .component("companies-table", CompaniesTable)
    .component("users-table", UsersTable)
    .component("company-action-card", CompanyActionCard)
    .component("submissions-page", SubmissionsPage)
    .component("external-workers-page", ExternalWorkersPage)
    .component("submission-reviews-summary", SubmissionReviewsSummary)
    .component("notification-component", NotificationComponent)
    .component("review-task", ReviewTask)
    .mount("#vue-main");

  if (process.env.SENTRY_DSN_JS) {
    Sentry.init({
      app,
      dsn: process.env.SENTRY_DSN_JS,
      environment: process.env.NODE_ENV,
      integrations: [
        Sentry.replayIntegration({
          maskAllText: true,
          blockAllMedia: true
        })
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0
    });
  }

  $(".select2_dropdown").select2();
});

window.triggerKnowledgeCentre = function (detail) {
  window.dispatchEvent(new CustomEvent("kb", { detail }));
};

window.triggerLeaveFeedbackPanel = function (detail) {
  window.dispatchEvent(new CustomEvent("triggerLeaveFeedbackPanel", { detail }));
};

window.triggerModal = function (detail) {
  window.dispatchEvent(new CustomEvent("modal", { detail }));
};

window.triggerTour = function (tour_id) {
  HelpHero.startTour(tour_id, {
    skipIfAlreadySeen: false,
    redirectIfNeeded: true
  });
};

window.closeModal = function () {
  window.dispatchEvent(new CustomEvent("closeModal", {}));
};

window.docReady = function (fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

window.enableTooltips = function () {
  $("[data-toggle='tooltip']").tooltip();
};
