<template>
  <div>
    <PrimeDataTable
      v-model:filters="filters"
      v-model:editingRows="editingRows"
      :paginator="!errorMessage && !!paginatorRows"
      :rows="paginatorRows"
      :rows-per-page-options="paginatorDropdownIncrements"
      :value="data"
      :row-class="rowData => `${rowData.error ? 'tw-text-red-700 tw-font-bold' : 'tw-text-black'} ${rowClasses} tw-font-semibold ${useRowClick ? 'hover:!tw-bg-disable-grey-100' : ''}`"
      :global-filter-fields="filterFields ? filterFields : columns.map(col => col.filterField || col.field)"
      :header-class="'tw-bg-transparent'"
      removable-sort
      :always-show-paginator="data.length > paginatorDropdownIncrements[0]"
      :loading="isLoading"
      :style="`min-height: ${isLoading ? '260px' : ''}`"
      :edit-mode="editMode"
      @cell-edit-complete="event => emit('on-cell-edit-complete', event)"
      @row-edit-save="event => emit('on-row-edit-save', event)"
      @row-click="event => emit('on-row-click', event)"
    >
      <template v-if="tableHeader || enableSearch" #header>
        <div class="tw-flex tw-justify-between tw-items-center">
          <div>
            <h2 class="tw-font-bold tw-text-xl tw-text-gray-700">
              {{ tableHeader }}
            </h2>
          </div>
          <div v-if="enableSearch && !errorMessage" class="tw-space-x-5 tw-flex tw-justify-between tw-items-center">
            <Button
              text="Clear"
              :colour="filters['global'].value ? 'confirm' : 'disabled'"
              :class="!filters['global'].value && 'tw-pointer-events-none tw-opacity-50'"
              type="transparent"
              icon="filter"
              @click="filters['global'].value = null"
            />
            <span class="p-input-icon-left">
              <i class="fa fa-search" />
              <input
                v-model="filters['global'].value"
                type="text"
                :class="`${css.forms.input} tw-pl-7 tw-text-black`"
                placeholder="Search"
              >
            </span>
          </div>
        </div>
      </template>

      <Column
        v-for="(col, index) in columns"
        :key="index"
        :field="col.field"
        :sortable="col.sortable"
        :sort-field="col.sortField || col.field"
        :header-class="headerClasses()"
        :body-class="bodyClasses(col)"
        footer-class="tw-py-3"
        footer-style="background-color: #ededed"
        :pt=" {
          bodyCell: {
            'exclude-from-click': col.excludeFromClick
          }
        }"
      >
        <template #header>
          <span>{{ col.header }}</span>
          <KnowledgeCentreTriggerIcon
            v-if="col.knowledgeCentreFaq"
            class="tw-ml-1"
            icon
            :faq="col.knowledgeCentreFaq"
          />
        </template>

        <template v-if="col.editable" #editor="{ data, field }">
          <div class="tw-flex tw-gap-1 tw-items-center">
            <InputNumber
              v-if="col.fieldType === 'currency'"
              v-model="data[field]"
              mode="currency"
              currency="GBP"
              :min="0"
              :input-class="`${css.forms.input} tw-border tw-text-black tw-px-2`"
            />
            <InputNumber
              v-else-if="col.fieldType === 'percentage'"
              v-model="data[field]"
              suffix="%"
              :input-class="`${css.forms.input} tw-border tw-text-black tw-px-2`"
            />
            <InputText
              v-else
              v-model="data[field]"
              :class="`${css.forms.input} ${hasErrored(data.slug, field) ? '!tw-border-red-700 tw-text-red-700' : 'tw-text-black'} tw-border tw-px-2`"
              @update:model-value="value => emit('on-input-text-update', {field: col.field, slug: data.slug, value})"
            />
            <Tooltip
              v-if="hasErrored(data.slug, field)"
              :content="cellMetadata[data.slug][field].message"
              :offset="[0, 15]"
              show-immediately
            >
              <Icon type="exclamation-triangle" />
            </Tooltip>
          </div>
        </template>

        <template v-if="col.footerComponent" #footer>
          <component :is="col.footerComponent" v-bind="footerProps" :field="col.field" />
        </template>

        <template #body="slotProps">
          <component :is="col.component" v-if="col.component" v-bind="slotProps.data[col.field]" />

          <span v-else-if="col.formatter">
            {{ col.formatterArguments ? col.formatter(slotProps.data[col.field], ...col.formatterArguments): col.formatter(slotProps.data[col.field]) }}
          </span>

          <span v-else :class="hasErrored(slotProps.data.slug, col.field) ? 'tw-text-red-700' : ''">
            {{ slotProps.data[col.field] }}
          </span>

          <DataTableEditIcons
            v-if="col.editable"
            :edit-mode="editMode"
            :cell-metadata="cellMetadata[slotProps.data.slug] && cellMetadata[slotProps.data.slug][col.field]"
          />
        </template>
      </Column>

      <Column
        v-if="editMode === 'row'"
        row-editor
        :header-class="headerClasses()"
        class="tw-w-24"
      >
        <template #roweditoriniticon>
          <Icon type="pencil" />
        </template>
        <template #roweditorsaveicon>
          <Icon type="check" />
        </template>
        <template #roweditorcancelicon>
          <Icon type="times" />
        </template>
      </Column>

      <template v-if="!isLoading" #empty>
        <p :class="`${errorMessage ? 'tw-text-red-600' : ''} tw-my-4 tw-px-[14px]`">
          {{ errorMessage ? errorMessage : 'No Records Found' }}
        </p>
      </template>

      <template #loading>
        <LoadingSpinner />
      </template>
    </PrimeDataTable>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import PrimeDataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import { FilterMatchMode } from "primevue/api";
import Column from "primevue/column";
import KnowledgeCentreTriggerIcon from "@generic/knowledge_centre_trigger_icon.vue";
import Button from "@generic/button_component.vue";
import LoadingSpinner from "@generic/loading_spinner.vue";
import DataTableEditIcons from "@generic/data_table_edit_icons.vue";
import Icon from "@generic/icon.vue";
import Tooltip from "@generic/tooltip.vue";
import "primevue/resources/themes/rhea/theme.css";
import { css } from "@shared/theme.js";

const editingRows = ref([]);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

const props = defineProps({
  data: {
    type: Array
  },
  columns: {
    type: Array
  },
  footerProps: {
    type: Object
  },
  paginatorRows: {
    type: Number
  },
  paginatorDropdownIncrements: {
    type: Array,
    default: () => [5, 10, 20, 50, 100]
  },
  enableSearch: {
    type: Boolean
  },
  altStyling: {
    type: Boolean // grey header rather than blue
  },
  tableHeader: {
    type: String,
    default: ""
  },
  errorMessage: {
    type: String,
    default: ""
  },
  filterFields: {
    type: Array,
    default: null
  },
  isLoading: {
    type: Boolean
  },
  rowClasses: {
    type: String
  },
  useRowClick: {
    type: Boolean
  },
  editMode: {
    type: String
  },
  cellMetadata: {
    type: Object,
    default: () => ({})
  }
});

const emit = defineEmits([
  "on-row-click",
  "on-cell-edit-complete",
  "on-row-edit-save",
  "on-input-text-update"
]);

const bodyClasses = col => {
  const alignmentClass = col.alignmentOverride || "tw-text-center";
  const colClasses = col.colClasses || "";
  const editableClass = col.editable ? "tw-cursor-pointer" : "";
  const excludeFromClickClass = col.excludeFromClick ? "tw-cursor-auto" : "";

  return `tw-py-3 tw-h-[60px] tw-px-2 tw-text-[12px] ${alignmentClass} ${colClasses} ${editableClass} ${excludeFromClickClass}`;
};

const headerClasses = () => {
  const baseClasses = "tw-py-3 tw-px-2 tw-text-center tw-uppercase tw-text-[11px]";
  const typeClasses = props.altStyling ? "tw-bg-gray-100 tw-border-b-4 tw-border-b-solid tw-border-gray-200 p-sortable-column-icon--alt" : "tw-bg-emp-blue tw-text-white";

  return `${baseClasses} ${typeClasses}`;
};

const hasErrored = computed(() => (slug, field) => (
  props.cellMetadata[slug]
    && props.cellMetadata[slug][field]
    && props.cellMetadata[slug][field].state === "error"
));
</script>

<style lang="scss">
.p-datatable-tbody {
  > tr:not(.p-datatable-emptymessage):nth-of-type(odd) {
    background-color: #FDFDFD;
  }

  > tr:not(.p-datatable-emptymessage):nth-of-type(even) {
    background-color: #F7F8F8;
  }
}

.p-paginator-page {
  border-radius: 100%;
}

.p-highlight {
  background-color: #6D8AA0;
  color: #ffffff;
}

.p-component {
  font-size: 13px;
}

.p-dropdown-label,
.p-dropdown-trigger {
  color: #000000;
}

.p-paginator {
  color: #000000;
  padding: 10px 0;
}

.p-sortable-column-icon {
  color: #FFFFFF;
  width: 12px;
  margin-left: 4px;
}

.p-datatable-header,
.p-datatable-loading-overlay {
  background-color: transparent;
}

.p-sortable-column-icon--alt  .p-sortable-column-icon {
  color: #666666;
}

.p-sortable-column-icon--alt.p-highlight  .p-sortable-column-icon {
  color: #FFFFFF;
}

.p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-datatable-loading-overlay {
  display: flex;
  align-items: flex-end;
  height: 230px;
  padding-bottom: 30px;
}

.p-datatable-emptymessage {
  td {
    padding: 0;
  }
}
</style>
