<template>
  <SlideFadeTransition>
    <banner-component
      v-if="integrationRefreshData.show_info"
      type="cta"
      class="tw-mt-4"
      title="Your Xero connection refresh is now complete!"
      :text="bannerText"
      :dismiss="true"
    />
  </SlideFadeTransition>
  <div>
    <div class="tw-grid tw-grid-cols-3 tw-my-6 tw-items-center tw-gap-x-4">
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <div>
          <tooltip content="Search for a supplier below.  Suppliers with very low spend are excluded.  If a supplier is missing, you may want to review your account code selection">
            <input
              v-model="filter.query"
              type="text"
              placeholder="Seach for a supplier"
              :class="theme.forms.input"
            >
          </tooltip>
        </div>
        <div>
          <Button
            type="transparent"
            color="cta"
            text="Clear Search"
            @click="filter.query = ''"
          />
        </div>
      </div>

      <div />

      <div>
        <div class="tw-flex tw-items-center tw-gap-x-2 tw-justify-end">
          <div class="tw-text-sm tw-cursor-pointer" @click="filter.hide_ignored = !filter.hide_ignored">
            <tooltip content="Only show suppliers that have been categorised">
              Only show suppliers in a category
            </tooltip>
          </div>
          <div>
            <input
              v-model="filter.hide_ignored"
              type="checkbox"
              :class="theme.forms.checkbox_rounded"
            >
          </div>
        </div>
      </div>
    </div>

    <table class="tw-w-full">
      <thead>
        <tr>
          <th :class="theme.tables.th_dark" />
          <th :class="theme.tables.th_dark" width="30%">
            Supplier
          </th>
          <th :class="theme.tables.th_dark">
            Total Spend
          </th>
          <th :class="['tw-text-center', theme.tables.th_dark]" width="50%">
            <PopUpTooltip ref="popUp">
              <tooltip ref="buttonTooltip" :content="tooltip" theme="light">
                Categorisation
              </tooltip>
              <template #popUpContent>
                <div class="tw-text-sm tw-text-left tw-tracking-normal tw-font-normal">
                  Please select a category of spend for each supplier; you will then be prompted to select the relevant invoices/transactions.
                </div>
              </template>
            </PopUpTooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(supplier, idx) in suppliers"
          :key="supplier.identifier"
        >
          <tr
            :class="[rowBackgroundColor(supplier, idx)]"
            @dblclick="toggleExpansion(supplier)"
          >
            <td :class="[theme.tables.td_no_text_colour, 'tw-text-center']">
              <FadeTransition mode="out-in">
                <div
                  v-if="!supplierCanExpand(supplier)"
                  class="tw-cursor-not-allowed"
                >
                  <tooltip
                    content="You need to assign this supplier to a category first"
                    theme="light"
                  >
                    <i class="fas fa-chevron-double-right tw-text-gray-300" />
                  </tooltip>
                </div>
                <div v-else class="tw-cursor-pointer">
                  <i
                    :class="[
                      'fas fa-chevron-double-right tw-duration-300',
                      isExpanded(supplier) ? 'tw-transform tw-rotate-90' : '',
                    ]"
                    @click="toggleExpansion(supplier)"
                  />
                </div>
              </FadeTransition>
            </td>

            <td :class="theme.tables.td_no_text_colour">
              <div class="tw-cursor-pointer" @click="toggleExpansion(supplier)">
                {{ supplier.name }}
              </div>
            </td>
            <td :class="theme.tables.td_no_text_colour">
              <span class="tw-font-mono tw-text-xs">
                {{
                  new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency: "GBP",
                  }).format(supplier.spend)
                }}
              </span>
            </td>
            <td :class="theme.tables.td_no_text_colour">
              <div class="tw-flex tw-items-center tw-gap-x-4">
                <template v-for="category in costCategories" :key="category">
                  <div class="tw-flex tw-gap-x-2 tw-items-center">
                    <div>
                      <input
                        :id="`supplier_category_${supplier.identifier}_${category}`"
                        v-model="supplier.subcost_type"
                        type="radio"
                        :class="[theme.forms.radio]"
                        :value="category"
                        @change="setSupplierSubcostType(supplier, category)"
                      >
                    </div>
                    <div>
                      <label
                        class="tw-font-medium tw-p-0 tw-m-0"
                        :for="`supplier_category_${supplier.identifier}_${category}`"
                      >
                        {{ category == 'Subcontractors' ? 'External Workers' : category }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </td>
          </tr>

          <tr v-if="isExpanded(supplier)" class="tw-bg-light-blue">
            <td colspan="4">
              <table class="tw-w-full tw-m-2 tw-text-sm">
                <thead>
                  <tr>
                    <th width="5%" align="middle">
                      <div class="tw-mx-auto tw-text-center">
                        <tooltip theme="light" content="Toggle all costs">
                          <input
                            type="checkbox"
                            :class="[theme.forms.checkbox_rounded, 'mx-auto']"
                            @change="toggleSupplierInclusion(supplier.identifier, $event.target.checked )"
                          >
                        </tooltip>
                      </div>
                    </th>
                    <th width="10%" @click="setCostFilterSort(['amount'], supplier)">
                      <span :class="[isSortedBy('amount') ? 'tw-underline' : 'hover:tw-underline', 'tw-cursor-pointer']">Amount</span>
                    </th>
                    <th width="10%" @click="setCostFilterSort(['date'], supplier)">
                      <span :class="[isSortedBy('date') ? 'tw-underline' : 'hover:tw-underline', 'tw-cursor-pointer']">Date</span>
                    </th>
                    <th width="60%">
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="cost in costsBySupplier[supplier.identifier]"
                    :key="cost.identifier"
                  >
                    <tr class="tw-divide-y tw-py-2">
                      <td align="middle">
                        <input
                          :id="`cost_${cost.identifier}`"
                          v-model="cost.include"
                          type="checkbox"
                          :class="[theme.forms.checkbox_rounded, 'mx-auto']"
                          :name="`cost_${cost.identifier}`"
                          @change="toggleCostInclusion(cost.id, supplier.identifier, cost.include)"
                        >
                      </td>
                      <td class="tw-font-mono tw-text-xs">
                        <div
                          class="tw-cursor-pointer"
                          @click="
                            cost.include = !cost.include;
                            toggleCostInclusion(cost.id, supplier.identifier, cost.include);
                          "
                        >
                          {{
                            new Intl.NumberFormat("en-GB", {
                              style: "currency",
                              currency: cost.currency,
                            }).format(cost.sub_total)
                          }}
                        </div>
                      </td>
                      <td>
                        <div
                          class="tw-cursor-pointer"
                          @click="
                            cost.include = !cost.include;
                            toggleCostInclusion(cost.id, supplier.identifier, cost.include);
                          "
                        >
                          {{ cost.date_paid }}
                        </div>
                      </td>
                      <!-- Line item column  -->
                      <td>
                        <div class="tw-flex tw-items-start tw-gap-x-2">
                          <div class="tw-text-gray-700 tw-text-xs tw-bg-gray-200 tw-rounded-full tw-px-2.5 tw-py-0.05 tw-mr-1" v-text="`${cost.source_type}`" />

                          <div class="tw-text-xs">
                            <template
                              v-for="line in cost.data.line_items"
                              :key="line.LineItemID"
                            >
                              <div
                                class="tw-flex tw-items-center tw-justify-between tw-w-full tw-gap-x-2"
                              >
                                <div v-text="line.Description" />

                                <tooltip :content="includedNominalToolTipContent(line.AccountCode)">
                                  <div
                                    v-if="nominalsLookup[line.AccountCode]"
                                    :class="[
                                      'tw-text-right tw-cursor-help',
                                      includedNominalColour(line.AccountCode),
                                    ]"
                                    v-text="nominalsLookup[line.AccountCode].name"
                                  />
                                </tooltip>
                              </div>
                            </template>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { debouncedWatch } from "@vueuse/core";
import { computed, onMounted, ref, watch, reactive } from "vue";
import { css } from "../../../../shared/theme.js";
import { en } from "../../../../shared/en.js";
import Button from "../../../generic/button_component.vue";
import PopUpTooltip from "../../../generic/pop_up_tooltip.vue";
import SlideFadeTransition from "../../../components/transitions/slide_fade.vue";
import FadeTransition from "../../../components/transitions/fade.vue";

export default {
  components: { SlideFadeTransition, FadeTransition, Button, PopUpTooltip },
  props: {
    sectionSlug: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const theme = css;
    const lang = en;

    const store = useStore();
    const expandedSuppliers = ref(["6896be99-c943-4881-819c-873f1a5fb6fb"]);
    const filter = reactive({ query: "" });
    const costsFilterSort = ref({ sort: ["date"] });
    const popUp = ref(null);
    const buttonTooltip = ref(null);
    const costCategories = Object.freeze(["Ignore", "Subcontractors", "Consumables", "Others"]);

    const suppliers = computed(() => store.getters["accountingIntegration/GET_SUPPLIERS"]);
    const supplierSubcostTypes = computed(() => store.getters["accountingIntegration/GET_SUPPLIER_SUBCOST_TYPES"]);
    const costsBySupplier = computed(() => store.getters["accountingIntegration/GET_COSTS_BY_SUPPLIER"]);
    const loadingSuppliers = computed(() => store.getters["accountingIntegration/GET_LOADING_SUPPLIERS"]);
    const nominalsLookup = computed(() => store.getters["accountingIntegration/GET_NOMINALS"]);
    const integrationRefreshData = computed(() => store.getters["accountingIntegration/GET_INTEGRATION_REFRESH_DATA"]);

    const bannerText = computed(() => {
      const costs = integrationRefreshData.value?.data_found?.costs;
      const suppliers = integrationRefreshData.value?.data_found?.suppliers;

      if (costs > 0 && suppliers > 0) return `${costs} costs and ${suppliers} suppliers found`;
      if (costs > 0 && suppliers === 0) return `${costs} additional costs found`;
      if (suppliers > 0 && costs === 0) return `${suppliers} additional suppliers found`;

      return "No additional suppliers or costs found";
    });

    onMounted(() => {
      store.dispatch("accountingIntegration/FETCH_SUPPLIERS", { integrationSlug: props.sectionSlug });
      store.dispatch("accountingIntegration/FETCH_NOMINALS", { integrationSlug: props.sectionSlug });
      store.dispatch("accountingIntegration/FETCH_INTEGRATION_REFRESH_DATA", { integrationSlug: props.sectionSlug });
    });

    // Watch the supplier subcost allocations object and show a tooltip if nothing
    // categorised yet
    watch(() => supplierSubcostTypes.value, val => {
      if (val) {
        if (Object.values(val).filter(x => !!x).length == 0) setTimeout(() => { popUp.value.triggerShow(); }, 1000);
        else popUp.value?.dismiss();
      }
    });

    debouncedWatch(
      filter,
      filterSuppliers,
      { debounce: 200, maxWait: 700 }
    );

    function filterSuppliers() {
      store.commit("accountingIntegration/SET_SUPPLIERS_FILTER", filter);
      store.dispatch("accountingIntegration/FILTER_SUPPLIERS", filter);
    }

    function setCostFilterSort(sort, supplier) {
      costsFilterSort.value.sort = sort;
      store.commit("accountingIntegration/SET_COSTS_SORT", costsFilterSort.value);
      store.dispatch("accountingIntegration/SORT_COSTS_FOR_SUPPLIER", { identifier: supplier.identifier });
    }

    function supplierCanExpand(supplier) {
      return (supplierSubcostTypes.value[supplier.identifier] != "Ignore" && supplierSubcostTypes.value[supplier.identifier] != null);
    }

    function setSupplierSubcostType(supplier, subcostType) {
      const couldExpand = supplierCanExpand(supplier);
      store.dispatch("accountingIntegration/SET_SUPPLIER_SUBCOST_TYPE", { integrationSlug: props.sectionSlug, identifier: supplier.identifier, subcostType });

      // If this cost was disallowed before but now we can open it, lets open it for user
      if (couldExpand == false && supplierCanExpand(supplier) == true && isExpanded(supplier) == false) toggleExpansion(supplier);

      if (isExpanded(supplier) == true && supplierCanExpand(supplier) == false) closeExpandedSupplier(supplier);
    }

    function toggleSupplierInclusion(identifier, include) {
      store.dispatch("accountingIntegration/TOGGLE_SUPPLIER", { integrationSlug: props.sectionSlug, identifier, include });
    }

    function toggleCostInclusion(id, supplier_identifier, include) {
      store.dispatch("accountingIntegration/TOGGLE_COST_INCLUSION", { integrationSlug: props.sectionSlug, id, include, supplier_identifier });
    }

    function isExpanded(supplier) {
      return (expandedSuppliers.value.indexOf(supplier.identifier) > -1);
    }

    function isSortedBy(fieldKey) {
      return costsFilterSort.value.sort.indexOf(fieldKey) > -1;
    }

    function toggleExpansion(supplier) {
      if (isExpanded(supplier)) {
        closeExpandedSupplier(supplier);
      } else if (supplierCanExpand(supplier)) {
        expandedSuppliers.value.push(supplier.identifier);

        if (costsBySupplier.value[supplier.identifier] == undefined) store.dispatch("accountingIntegration/FETCH_COSTS_BY_SUPPLIER", { integrationSlug: props.sectionSlug, identifier: supplier.identifier });
      }
    }

    function closeExpandedSupplier(supplier) {
      expandedSuppliers.value = expandedSuppliers.value.filter(x => x != supplier.identifier);
    }

    function rowBackgroundColor(supplier, idx) {
      if (isExpanded(supplier)) return "tw-bg-cta-blue tw-text-white tw-font-bold";

      return idx % 2 == 0 ? "tw-bg-gray-50 tw-text-gray-600" : "tw-text-gray-600 tw-bg-white";
    }

    function includedNominalColour(code) {
      return nominalsLookup.value[code].include == true ? "tw-text-green-500" : "tw-text-red-500";
    }

    // When hovering over the nominal name at a line item level
    function includedNominalToolTipContent(code) {
      return nominalsLookup.value[code]?.include == true ? lang.integration.accounting.nominalInclusion.included : lang.integration.accounting.nominalInclusion.excluded;
    }

    watch(integrationRefreshData, val => {
      let fetchedAdditionalData = false;

      if (val?.requested && !val?.completed) {
        setTimeout(() => {
          store.dispatch("accountingIntegration/FETCH_INTEGRATION_REFRESH_DATA", { integrationSlug: props.sectionSlug });
        }, 60000);
      }

      if (val?.requested && val?.completed && !fetchedAdditionalData) {
        store.dispatch("accountingIntegration/FETCH_SUPPLIERS", { integrationSlug: props.sectionSlug });
        store.dispatch("accountingIntegration/FETCH_NOMINALS", { integrationSlug: props.sectionSlug });
        fetchedAdditionalData = true;
      }
    });

    return {
      loadingSuppliers,
      suppliers,
      theme,
      toggleSupplierInclusion,
      toggleExpansion,
      isExpanded,
      rowBackgroundColor,
      costCategories,
      filter,
      setSupplierSubcostType,
      costsBySupplier,
      supplierSubcostTypes,
      supplierCanExpand,
      toggleCostInclusion,
      includedNominalColour,
      nominalsLookup,
      costsFilterSort,
      setCostFilterSort,
      isSortedBy,
      includedNominalToolTipContent,
      popUp,
      buttonTooltip,
      integrationRefreshData,
      bannerText
      // nominalLabelColour
    };
  }
};
</script>
