<template>
  <component
    :is="component"
    v-if="!loading"
    :slug="slug"
  />
</template>

<script>
import { onMounted, computed } from "vue";
import { useStore } from "vuex";
import AllocateCosts from "./allocate_costs.vue";
import Summary from "./summary.vue";
import costLinkingTabs from "./cost_linking_tabs.js";

import { pageComponents } from "./project_cost_breakdown_steps.js";

export default {
  components: {
    AllocateCosts,
    Summary
  },
  props: {
    slug: {
      required: true,
      type: String
    },
    page: {
      required: true,
      type: String,
      default: "allocate_costs"
    }
  },
  setup(props) {
    const store = useStore();
    store.commit("project_cost_breakdown/SET_LOADING", true);
    const loading = computed(() => store.getters["project_cost_breakdown/GET_LOADING"]);
    const component = computed(() => pageComponents[props.page]);

    onMounted(() => {
      const promises = [
        store.commit("sections/SET_SECTION_TYPE", "ProjectCostBreakdown"),
        store.dispatch("users/SET_USER"),
        store.dispatch("project_cost_breakdown/FETCH_PROJECT_COST_BREAKDOWN", { slug: props.slug }),
        store.dispatch("assignments/FETCH_ASSIGNMENTS", [`ProjectCostBreakdown:${props.slug}`])
      ];

      Promise.all(promises).then(() => {
        store.dispatch('project_cost_breakdown/FETCH_RD_PROJECTS').then(() => {
          store.commit('project_cost_breakdown/SET_LOADING', false);
        });
      });
    });

    return {
      component,
      loading
    };
  }
};
</script>
