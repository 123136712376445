<template>
  <Disclosure v-slot="{ open }">
    <div class="tw-bg-gray-50 tw-rounded-lg tw-px-4">
      <DisclosureButton class="tw-w-full tw-inline-flex tw-items-center focus:tw-outline-none tw-py-2.5">
        <div class="tw-flex-1">
          <slot name="title-content" />
        </div>
        <i :class="['far fa-chevron-down tw-duration-300 tw-flex-none', open ? 'tw-transform tw-rotate-180' : '']" />
      </DisclosureButton>
      <Transition
        enter-active-class="tw-transition tw-duration-300 tw-ease-out"
        enter-from-class="tw-transform tw-scale-y-90 tw-opacity-0 tw--translate-y-1/4"
        enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
        move-class="tw-transition tw-duration-300"
        leave-active-class="tw-transition tw-ease-out tw-duration-300"
        leave-from-class="tw-transform tw-opacity-100 tw-scale-100"
        leave-to-class="tw-transform tw-opacity-0 tw-scale-y-90 tw--translate-y-1/4"
      >
        <DisclosurePanel class="tw-pt-1 tw-pb-3">
          <slot name="dropdown-content" />
        </DisclosurePanel>
      </Transition>
    </div>
  </Disclosure>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  }
};
</script>
