<template>
  <div class="tw-sticky tw-top-1">
    <div class="tw-flex tw-flex-col tw-gap-2 lg:tw-gap-4">
      <state-wrapper component="ReviewPanel">
        <ReviewPanel
          :user-slug="userSlug"
          :section-slug="section.slug"
          :section-type="section.type"
        />
      </state-wrapper>
      <div
        v-show="showSectionCard"
        class="tw-bg-white tw-rounded-lg tw-shadow tw-flex tw-flex-col tw-gap-2 lg:tw-py-4 tw-py-2 tw-px-2 lg:tw-px-6"
      >
        <state-wrapper ref="sectionStatus" component="SectionStatus">
          <SectionStatus :section="section" />
        </state-wrapper>
        <hr v-if="sectionStatus && sectionStatus.show && (showFeedback || showAssignComplete)" class="tw-hidden lg:tw-block tw-my-2">
        <state-wrapper ref="assign" component="AssignIcon">
          <AssignIcon
            :fields="section.fields"
            :section-type="section.type"
            :section-slug="section.slug"
            assign-icon-type="SectionAssign"
          />
        </state-wrapper>
        <state-wrapper ref="complete" component="CompleteIcon">
          <CompleteIcon
            :fields="section.fields"
            :section-type="section.type"
            :section-slug="section.slug"
            complete-icon-type="SectionComplete"
          />
        </state-wrapper>
        <hr v-if="showFeedback && showAssignComplete" class="tw-hidden lg:tw-block tw-my-2">
        <state-wrapper ref="feedbackSummary" component="FeedbackSummary">
          <SlideFadeTransition>
            <div v-if="unresolvedFeedbackCount > 0">
              <div class="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-1">
                <div class="tw-hidden lg:tw-block tw-font-semibold tw-text-base">
                  Feedback
                </div>
                <div class="tw-h-7 tw-flex tw-items-center tw-gap-1.5 tw-px-2 tw-rounded-lg tw-text-warning-yellow tw-bg-amber-50">
                  <i class="far fa-comment-alt fa-1x" />
                  <div class="tw-h-6 tw-w-2 tw-relative tw-inline-block">
                    <SlideUpTransition>
                      <span :key="unresolvedFeedbackCount" class="tw-absolute tw-font-semibold">
                        {{ unresolvedFeedbackCount }}
                      </span>
                    </SlideUpTransition>
                  </div>
                </div>
              </div>
              <FadeTransition id="feedback-list" group class="tw-relative tw-hidden lg:tw-block tw-text-xs tw-text-light-grey-blue tw-font-semibold">
                <div
                  v-for="feedback_group in feedbacksToShow"
                  :key="feedback_group.dynamic_form_key"
                  class="tw-w-full tw-flex lg:tw-justify-between tw-gap-x-2 tw-cursor-pointer tw-mt-3"
                  @click="scroll(feedback_group.dynamic_form_key)"
                >
                  <span>
                    Step {{ stepCount(section.type, feedback_group.dynamic_form_key, section.new_project_methodology) }}: {{ feedback_group.feedbacks[0].dynamic_form_title }}
                  </span>
                  <span class="tw-text-warning-yellow hover:tw-text-yellow-500 tw-flex-none">See feedback</span>
                </div>
              </FadeTransition>
            </div>
            <div v-else-if="allFeedbacksResolved" class="tw-hidden lg:tw-block">
              <div class="tw-text-green-600 tw-flex tw-items-center tw-justify-end tw-gap-2 tw-text-base tw-font-semibold">
                All feedback has been resolved
                <i class="fas fa-check-circle fa-lg tw-px-0.5" />
              </div>
            </div>
          </SlideFadeTransition>
        </state-wrapper>
      </div>
      <state-wrapper component="SectionActions">
        <SectionActions
          :section="section"
        />
      </state-wrapper>
      <state-wrapper component="AuditHistoryCarousel">
        <AuditHistoryCarousel
          :section-type="section.type"
          :section-slug="section.slug"
        />
      </state-wrapper>
    </div>
  </div>
</template>

<script>
import { mapGetters, useStore } from "vuex";
import { ref, computed } from "vue";

import AssignIcon from "./assign_icon.vue";
import CompleteIcon from "./complete_icon.vue";
import AuditHistoryCarousel from "./audit_history_carousel.vue";
import ReviewPanel from "../sections/review_panel.vue";
import SectionActions from "./section_actions.vue";
import SectionStatus from "../sections/section_status.vue";

import SlideUpTransition from "../transitions/slide_up.vue";
import SlideFadeTransition from "../transitions/slide_fade.vue";
import FadeTransition from "../transitions/fade.vue";

import { GET_UNRESOLVED_FEEDBACKS_FOR_SECTION } from "../../../vuex-store/modules/feedbacks.js";

export default {
  components: {
    AssignIcon,
    CompleteIcon,
    AuditHistoryCarousel,
    SlideUpTransition,
    SlideFadeTransition,
    FadeTransition,
    ReviewPanel,
    SectionActions,
    SectionStatus
  },
  props: {
    section: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const store = useStore();
    const userSlug = computed(() => store.getters["users/GET_USER_SLUG"]);
    const userType = computed(() => store.getters["states/GET_USER_TYPE"]);
    const unresolvedFeedbackCount = computed(() => store.getters["feedbacks/GET_UNRESOLVED_FEEDBACKS_COUNT"](props.section.type, props.section.slug));
    const allFeedbacks = computed(() => store.getters["feedbacks/GET_FEEDBACKS_FOR_SECTION"](props.section.type, props.section.slug));
    const allFeedbacksResolved = computed(() => userType.value !== "reviewer" && allFeedbacks.value.length > 0 && _.every(allFeedbacks.value, "resolved"));

    const sectionStatus = ref(null);
    const assign = ref(null);
    const complete = ref(null);
    const feedbackSummary = ref(null);

    const showAssignComplete = computed(() => assign.value?.show || complete.value?.show);
    const showFeedback = computed(() => feedbackSummary.value?.show && (unresolvedFeedbackCount.value > 0 || allFeedbacksResolved.value));
    const showSectionCard = computed(() => sectionStatus.value?.show || assign.value?.show || complete.value?.show || showFeedback.value);

    return {
      sectionStatus,
      assign,
      complete,
      userType,
      userSlug,
      feedbackSummary,
      showSectionCard,
      showFeedback,
      unresolvedFeedbackCount,
      allFeedbacksResolved,
      showAssignComplete
    };
  },
  data() {
    return {
      steps: {
        Project: ["about_the_project", "competent_professional", "project_background", "project_activities"],
        NewProject: ["project_details", "competent_professional", "background_objective", "project_uncertainties"],
        Challenge: ["challenge_overview", "validate_challenge", "addressing_the_challenge", "outcome_of_challenge"]
      }
    };
  },
  computed: {
    ...mapGetters({
      getUnresolvedFeedbacksForSection: `feedbacks/${GET_UNRESOLVED_FEEDBACKS_FOR_SECTION}`
    }),
    feedbacksToShow() {
      const allUnresolvedFeedbacks = this.getUnresolvedFeedbacksForSection(this.section.type, this.section.slug);
      const sectionType = this.section?.new_project_methodology ? "NewProject" : this.section.type;

      return _.chain(allUnresolvedFeedbacks)
            .sort((a, b) => this.steps[sectionType].indexOf(a.dynamic_form_key) - this.steps[sectionType].indexOf(b.dynamic_form_key))
            .groupBy("dynamic_form_key")
            .map((value, key) => ({ dynamic_form_key: key, feedbacks: value }))
            .value();
    }
  },
  methods: {
    // Step number is hard-coded here, needs to be addressed at some point
    stepCount(feedbackable_type, dynamic_form_key, new_project_methodology = false) {
      feedbackable_type = new_project_methodology ? "NewProject" : feedbackable_type;

      return this.steps[feedbackable_type].indexOf(dynamic_form_key) + 1;
    },
    scroll(dynamicFormKey) {
      const fieldGroupNode = document.querySelector(`[group-key="${dynamicFormKey}"]`);

      if (fieldGroupNode) {
        fieldGroupNode.scrollIntoView({
          behavior: "smooth"
        });
      }
    }
  }
};
</script>
