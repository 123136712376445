<template>
  <CardComponent :dividers="true">
    <template #title>
      <div class="tw-flex tw-flex-col tw-gap-1">
        <div class="tw-flex tw-justify-between tw-items-center">
          <button-component
            :id="`company-dashboard-claim-period-${claimPeriod.slug}`"
            :link="`/claim_periods/${claimPeriod.slug}/dashboard`"
          >
            <div :class="[theme.typography.h2, 'tw-font-semibold']">
              {{ claimPeriod.title }}
            </div>
          </button-component>
          <div class="tw-flex tw-flex-col tw-items-end">
            <span
              class="
                tw-uppercase
                tw-text-xs
                tw-tracking-tight
                tw-font-semibold
                tw-text-disable-grey
              "
            >
              {{ awarded ? 'Awarded' : 'Estimated Award' }}
            </span>
            <span :class="[theme.typography.h2, 'tw-font-semibold']">
              {{ formatCurrency(costData.claim_value) }}
            </span>
          </div>
        </div>
        <div class="tw-flex tw-justify-between">
          <div class="tw-flex tw-gap-2">
            <badge-component
              v-if="claimPeriod.scheme_type_label"
              size="small"
              :rounded="false"
              :text="claimPeriod.scheme_type_label"
              colour="disabled"
              type="light"
            />
            <badge-component
              size="small"
              :rounded="false"
              :text="claimPeriod.submit_by_emp === false ? 'You' : 'EmpowerRD'"
              :tooltip="claimPeriod.submit_by_emp === false ? 'You have chosen to submit your claim yourselves' : 'You have chosen EmpowerRD to submit your claim on your behalf'"
              icon="paper-plane"
              colour="disabled"
              type="light"
            />
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <div class="tw-flex tw-justify-between">
        <div class="tw-flex tw-gap-4">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <span class="tw-uppercase tw-text-xs tw-tracking-tighter tw-font-semibold">
              Accounting Period
            </span>
            <span>
              <span class="tw-text-sm tw-tracking-tight tw-text-disable-grey tw-font-medium">
                {{ claimPeriod.accounting_period_ended ? 'Ended' : 'Open' }}
              </span>
            </span>
          </div>
          <div class="tw-flex tw-flex-col tw-gap-1">
            <span class="tw-uppercase tw-text-xs tw-tracking-tighter tw-font-semibold">
              Accounts
            </span>
            <tooltip :content="claimPeriod.accounts_filed_date_formatted ? `Your accounts were submitted to Companies House on ${claimPeriod.accounts_filed_date_formatted}` : null">
              <span class="tw-text-sm tw-tracking-tight tw-text-disable-grey tw-font-medium">
                {{ claimPeriod.accounts_filed_date_formatted ? 'Filed' : 'Not Filed' }}
              </span>
            </tooltip>
          </div>
        </div>
        <div class="tw-flex">
          <div class="tw-flex tw-flex-col tw-gap-1">
            <span class="tw-uppercase tw-text-xs tw-tracking-tighter tw-font-semibold">
              Qualifying Expenditure
            </span>
            <span class="tw-flex tw-items-center tw-gap-2 tw-tracking-tight">
              <span class="tw-text-sm tw-text-disable-grey tw-font-medium">
                {{ formatCurrency(costData.total_qualifying_expenditure) }}
              </span>
              <span
                v-if="!claimPeriod.first_claim && costData.total_qualifying_expenditure_percent_change"
                :class="['tw-text-xs tw-font-medium', costData.total_qualifying_expenditure_percent_change > 0 ? 'tw-text-confirm-green' : 'tw-text-error-red']"
              >
                {{ formatPercentage(costData.total_qualifying_expenditure_percent_change) }}
                <i :class="['far', `fa-long-arrow-${costData.total_qualifying_expenditure_percent_change > 0 ? 'up' : 'down'}`]" />
              </span>
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-if="lastActiveSection && !claimPeriod.emp_approved" #footer>
      <div class="tw-flex tw-justify-between tw-items-center">
        <div class="tw-text-xs tw-font-semibold">
          <span>Last Updated Section: </span>
          <span class="tw-text-disable-grey-750">{{ lastActiveSection.title }}</span>
        </div>
        <button-component
          id="pickup-where-you-left-off"
          text="Pick up where you left off"
          icon="redo"
          size="small"
          type="transparent"
          :tooltip="`Click here to continue working on ${lastActiveSection.title}`"
          :uppercase="true"
          :link="lastActiveSection.link"
          :track="{
            claim_period_slug: claimPeriod.slug,
            section_slug: lastActiveSection.slug,
            section_type: lastActiveSection.section_type
          }"
        />
      </div>
    </template>
  </CardComponent>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";
import { css } from "../../../../shared/theme.js";
import CardComponent from "../../../generic/card_component.vue";
import { numberWithCommas } from "../../../../shared/helpers.js";

export default {
  name: "ClaimPeriodComponent",
  components: {
    CardComponent
  },
  props: {
    claimPeriod: {
      type: Object,
      required: true
    },
    company: {
      type: Object,
      required: true
    },
    userRole: {
      type: String,
      required: true
    },
    awarded: {
      type: Boolean,
      required: false,
      default: false
    },
    lastActiveSection: {
      type: Object,
      required: false
    },
    costData: {
      type: Object,
      required: false
    }
  },
  setup(props) {
    const store = useStore();
    const theme = css;

    const statusColour = computed(() => {
      if (["Complete", "Archived", "Some credit received"].includes(props.claimPeriod.claim_status)) {
        return "confirm";
      } else if (["Awaiting Submission to HMRC", "Awaiting Kickoff"].includes(props.claimPeriod.claim_status)) {
        return "warning";
      } else {
        return "cta";
      }
    });

    return {
      theme,
      statusColour,
      numberWithCommas
    };
  },
  methods: {
    formatCurrency(number) {
      const roundedNumber = Math.floor(number);

      return `£${numberWithCommas(roundedNumber)}`;
    },
    formatPercentage(number) {
      const roundedPercent = Math.floor(number * 100);
      const sign = roundedPercent > 0 ? "+" : "";

      return `${sign + roundedPercent}%`;
    }
  }
};
</script>
